#resizer {
    z-index: 1;
    background-color: #ccc;
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    cursor: ew-resize;
}
