body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scanned-photo-popup > .mapboxgl-popup-content {
  background: #252525;
  padding: 0;
}
.scanned-photo-popup.mapboxgl-popup-anchor-bottom  >  .mapboxgl-popup-tip {
  border-top-color: #252525;
}
.scanned-photo-popup.mapboxgl-popup-anchor-top  >  .mapboxgl-popup-tip {
  border-bottom-color: #252525;
}

.mapboxgl-ctrl-geocoder--icon-search {
  visibility: hidden;
}
.mapboxgl-ctrl-geocoder--icon {
  visibility: hidden;
}
.mapboxgl-ctrl-geocoder--button {
  visibility: hidden;
}

.mapboxgl-ctrl-geocoder--input {
  background-color: #fff;
  color: #252525;
  border: 1px solid #fff;
  border-radius: 0;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.suggestions-wrapper {
  background-color: #fff;
  color: #252525;
  font-size: 1rem;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}